import React, { useState } from "react"
import { DefaultHeader } from "components/headers/default-header"
import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { FollowBtn } from "components/follow-btn"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { BrandLogo } from "components/brand-logo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLine,
  faTwitterSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons"
import {
  faTrophy,
  faTicketAlt,
  faQuestionCircle,
  faBook,
  faChartBar,
  faMapMarkedAlt,
  faEnvelopeOpenText,
  faWindowMaximize,
  faPaperPlane,
  faGifts,
  faGift,
  faImages,
  faDatabase,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons"

const Index = () => {
  const [isShowFollowBtn, setIsShowFollowBtn] = useState(false)
  useScrollPosition(({ prevPos, currPos }) => {
    const isVisible = currPos.y > -250 ? false : true
    setIsShowFollowBtn(isVisible)
  })
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo pagetitle="PARKLoT" />
        <main className="main">
          <section className="hero">
            <div className="hero__container">
              <div className="hero__inner">
                <div className="hero__catch">
                  {/* FIXME: コピーは仮 */}
                  <h1 className="hero__title">
                    SNS活动工具，
                    <br />
                    加速粉丝获取
                    <br />
                    和销售推广
                  </h1>
                  <p className="hero__lead">
                    即时获胜，分发优惠券，使用UGC!
                    <br />
                    你可以开展各种各样的社交网络活动!
                  </p>
                  <div className="hero__icons">
                    <FontAwesomeIcon
                      icon={faTwitterSquare}
                      className="icon--twitter"
                    />
                    <FontAwesomeIcon
                      icon={faInstagramSquare}
                      className="icon--ig"
                    />
                    <FontAwesomeIcon icon={faLine} className="icon--line" />
                  </div>
                  <div className="hero__features">
                    <StaticImage
                      layout="constrained"
                      alt="丰富的功能"
                      src="../../images/index/words_01.png"
                    />
                    <StaticImage
                      layout="constrained"
                      alt="50,000日元起"
                      src="../../images/index/words_02.png"
                    />
                    <StaticImage
                      layout="constrained"
                      alt="在短短一天内举行"
                      src="../../images/index/words_03.png"
                    />
                    <StaticImage
                      layout="constrained"
                      alt="成本效益高"
                      src="../../images/index/words_04.png"
                    />
                  </div>
                </div>
                <figure className="hero__images">
                  {/* FIXME: altは正式決定したコピーに変更 */}
                  <StaticImage
                    layout="constrained"
                    alt="支持社交网络活动的倡议，从规划到执行!"
                    src="../../images/index/fv_mock.png"
                  />
                </figure>
              </div>
              <div className="hero__btn-container">
                <a
                  className="ga-download-top hero__btn btn--brand"
                  href="https://hub.park-lot.com/document-download"
                  target="_blank"
                  rel="noreferrer"
                >
                  下载3分钟的文件（免费）
                </a>
              </div>
            </div>
          </section>
          <BrandLogo />
          <section className="trouble index">
            <div className="trouble__container l-container">
              <h2 className="index__title">你有这些问题吗？</h2>
              <ul className="trouble__list">
                <li className="trouble__item">
                  预算和实施频率
                  <br />
                  数量有限
                  <br />
                  规划需要时间
                  <figure className="trouble__img-wrapper">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="规划需要时间"
                      src="../../images/index/person-1.png"
                    />
                  </figure>
                </li>
                <li className="trouble__item">
                  我想减少竞选操作的麻烦！
                  <figure className="trouble__img-wrapper">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="我想减少竞选操作的麻烦！"
                      src="../../images/index/person-2.png"
                    />
                  </figure>
                </li>
                <li className="trouble__item">
                  我需要在短时间内
                  <br />
                  有效地推广我的产品!
                  <figure className="trouble__img-wrapper">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="我需要在短时间内有效地推广我的产品!"
                      src="../../images/index/person-3.png"
                    />
                  </figure>
                </li>
                <li className="trouble__item">
                  宣传活动
                  <br />
                  并不导致销售。
                  <figure className="trouble__img-wrapper">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="宣传活动并不导致销售。"
                      src="../../images/index/person-4.png"
                    />
                  </figure>
                </li>
              </ul>
            </div>
          </section>
          <section className="solution">
            <div className="solution__container l-container">
              <h2 className="solution__title">
                {/* <span className="solution__small"></span> */}
                <span className="solution__row">
                  <StaticImage
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="パークロット"
                    src="../../images/index/solution-logo.png"
                    className="solution__logo"
                  />
                </span>
                是解决你的问题的方法!!
              </h2>
              <div className="solution__card-container">
                <div className="solution__card">
                  <h3 className="solution__subtitle">将繁琐的任务自动化!</h3>
                  <div className="solution__img">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="将繁琐的任务自动化!"
                      src="../../images/index/solution-1.png"
                    />
                  </div>
                  <p className="solution__text">
                    PARKLoT实现了
                    <strong className="index__strong">抽签</strong>、
                    <strong className="index__strong">收集申请信息</strong>和
                    <strong className="index__strong">通知结果</strong>
                    的整个过程的自动化!
                    <br />
                    它减少了花在行政工作上的时间，为创造基本价值创造了时间，如与客户的沟通。
                  </p>
                </div>
                <div className="solution__card">
                  <h3 className="solution__subtitle">想到就去实施吧!</h3>
                  <div className="solution__img">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="想到就去实施吧!"
                      src="../../images/index/solution-2.png"
                    />
                  </div>
                  <p className="solution__text">
                    <strong className="index__strong">活动在短短10分</strong>
                    钟内就开始了!
                    <br />
                    这是一种及时推广你的服务和产品的好方法，不会错过机会，
                    <strong className="index__strong">
                      从而导致立即产生效果。
                    </strong>
                  </p>
                </div>
                <div className="solution__card">
                  <h3 className="solution__subtitle">
                    改进和补充，只要你愿意，次数不限!
                  </h3>
                  <div className="solution__img">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="改进和补充，只要你愿意，次数不限!"
                      src="../../images/index/solution-3.png"
                    />
                  </div>
                  <p className="solution__text">
                    运动应该结合短期和中期的措施!
                    <br />
                    PARKLoT的活动
                    <strong className="index__strong">
                      可以按月进行，次数不限，
                    </strong>
                    让你可以进行有效的活动，
                    <strong className="index__strong">
                      最大限度地提高LTV。
                    </strong>
                  </p>
                </div>
              </div>
              <div className="solution__flow flow">
                <div className="flow__text-container">
                  <div className="flow__text-box">
                    <h3 className="flow__title">
                      有了固定价格计划，你可以进行
                      <br />
                      <strong className="index__strong">
                        完全无限制的<small className="index__small">※</small>
                      </strong>
                      宣传。
                    </h3>
                    <p className="index__text">
                      一般来说，当试图在Twitter上开展活动时，由于预算和次数有限，很容易花太多时间进行策划，即使反应不好，时间和预算成本也很高，人们往往会犹豫是否要再次开展活动。
                    </p>
                    <p className="index__text">
                      有了PARKLoT，你可以在想到的时候立即实施活动，或者通过试验和错误，同时监测活动的反应。
                      <br />
                      <strong className="index__strong">
                        你可以利用机会，将你的服务和产品的公关与结果联系起来。
                      </strong>
                    </p>
                    <p className="index__text flow__detail">
                      改进也减少了花在确保预算和合同上的时间，
                      <strong className="index__strong">
                        因此走向结果的周期不会被拖慢，你可以专注于你的活动。
                      </strong>
                    </p>
                    <p className="flow__notes index__notes">
                      ※根据计划和使用情况，可能会有一些限制
                    </p>
                  </div>
                  <div className="flow__card">
                    <p className="flow__text">
                      削减与内部审查和合同有关的时间。
                    </p>
                    <p className="flow__text">快速的PDCA循环走向结果!</p>
                    <div className="flow__img--sp">
                      <StaticImage
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="PDCA循环走向结果"
                        src="../../images/index/flow-sp.png"
                      />
                    </div>
                    <div className="flow__img--pc">
                      <StaticImage
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="快速的!"
                        src="../../images/index/flow-pc.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="feature index">
            <div className="feature__container l-container">
              <h2 className="index__title">
                可利用PARKLoT
                <br />
                开展的活动
              </h2>
              <p className="index__lead">
                开展各种活动，
                <br />
                传播产品和服务的吸引力。
              </p>
              <p className="index__lead">
                通过与客户一起享受激动人心的活动，
                <br />
                增加粉丝数量。
              </p>
              <ul className="feature__list">
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faTrophy}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">速赢</p>
                  <p className="feature__item--text">
                    结果由DM通知。你可以检查你的中奖结果，而不需要在Twitter上验证!非常受欢迎的
                    "当场取胜 "政策。
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faTicketAlt}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">优惠券发放</p>
                  <p className="feature__item--text">
                    有助于吸引顾客到你的实体店!分发三种类型的优惠券："可取消的"、"定时的
                    "和 "一段时间内无限制的"。
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">测验、诊断和小游戏</p>
                  <p className="feature__item--text">
                    非常有效地将人们引导到他们自己的网站!可以创造更多刺激的运动，如刮刮乐和老虎机。
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">自动发送礼券</p>
                  <p className="feature__item--text">
                    通过DM向获奖者发送数字礼券!受欢迎的Twitter Follow
                    RT活动将立即启动!
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faMobileAlt}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">推特行动</p>
                  <p className="feature__item--text">
                    甚至代表公司自动发布预约信息、RT活动和DM发送!争取建立一个能带来成果的Twitter受众。
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faChartBar}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">转发用户集合</p>
                  <p className="feature__item--text">
                    检索所有转发的用户。它可以用来进行各种分析，以支持有效的活动规划！
                  </p>
                </li>
              </ul>
              <div className="option">
                <h3 className="option__title">加上丰富的免费选项</h3>
                <ul className="option__list">
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faMapMarkedAlt}
                        className="option__icon"
                      />
                    </div>
                    收集送货地址
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faEnvelopeOpenText}
                        className="option__icon"
                      />
                    </div>
                    获奖者的自动传送
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faWindowMaximize}
                        className="option__icon"
                      />
                    </div>
                    网站指导
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon icon={faLine} className="option__icon" />
                    </div>
                    LINE指导
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon icon={faBook} className="option__icon" />
                    </div>
                    材料的分发
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faGifts}
                        className="option__icon"
                      />
                    </div>
                    为每个礼物设置获胜的概率
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon icon={faGift} className="option__icon" />
                    </div>
                    设定获胜标准
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faImages}
                        className="option__icon"
                      />
                    </div>
                    当选的和不成功的图像定制
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faDatabase}
                        className="option__icon"
                      />
                    </div>
                    数据收集和CSV输出
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <div className="contact">
            <p className="contact__text">
              现在就下载3分钟的
              <br />
              PARKLoT和成功案例!
            </p>
            <a
              className="ga-contact-middle contact__link btn-white"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              下载该文件（免费）
            </a>
          </div>
          <section className="case">
            <div className="case__container l-container">
              <h2 className="index__title">举办的活动实例</h2>
              <p className="index__lead">
                PARKLoT提供的公积金是到
                <br />
                现在为止用Twitter即时赢取的，
                <br />
                这是不可想象的。
              </p>
              <div className="case__item-container">
                <div className="case__item">
                  <div className="case__head">
                    <div className="case__img-wapper">
                      <StaticImage
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="这是不可想象的。"
                        src="../../images/index/case-sutenai.png"
                        maxwidth="155px"
                      />
                    </div>
                  </div>
                  <div className="case__body">
                    <h3 className="case__title">
                      SUTENAI<span className="case__small">公司</span>
                    </h3>
                    <p className="case__text">
                      就在2020年1月该品牌推出时，推特即时赢活动也随之启动。作为一个新推出的品牌的宣传活动的一部分，在有限的预算下推出。在短短两周内，40名追随者变成了2960多名追随者。
                    </p>
                    <div className="case__data-container">
                      <div className="case__data-box">
                        <p className="case__dtitle">追随者的数量增加</p>
                        <p className="case__data">
                          2,934
                          {/* <span className="case__unit">followers</span> */}
                        </p>
                      </div>
                      <div className="case__data-box">
                        <p className="case__dtitle">CPF</p>
                        <p className="case__data">
                          2.22
                          <span className="case__unit">日元</span>
                        </p>
                        <small className="case__notes">※包括礼品费用</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="case__item">
                  <div className="case__head">
                    <div className="case__img-wapper">
                      <StaticImage
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="案例研究的图像"
                        src="../../images/index/case-nor.png"
                        maxwidth="155px"
                      />
                    </div>
                  </div>
                  <div className="case__body">
                    <h3 className="case__title">
                      NOR.<span className="case__small">公司</span>
                    </h3>
                    <p className="case__text">
                      NOR.是一个处于第一年的新品牌化妆品品牌，过去曾开展过几次自我管理的活动，并获得了6920名追随者，但彩票公司在运营成本方面很吃力，包括与获奖者的沟通。因此，实施了一项使用PARKLoT的活动，其结果是追随者的数量创下了新高，并大大降低了运营成本。
                    </p>
                    <div className="case__data-container">
                      <div className="case__data-box">
                        <p className="case__dtitle">追随者的数量增加</p>
                        <p className="case__data">
                          4,270
                          {/* <span className="case__unit">名</span> */}
                        </p>
                      </div>
                      <div className="case__data-box">
                        <p className="case__dtitle">CPF</p>
                        <p className="case__data">
                          0.7
                          <span className="case__unit">日元</span>
                        </p>
                        <small className="case__notes">※包括礼品费用</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="price index">
            <div className="price__container l-container">
              <h2 className="index__title">收费</h2>
              <p className="index__lead price__lead">
                推荐的即赢活动计划可用于各种营销活动。
              </p>
              <div className="price-card__container">
                <div className="price-card">
                  <h3 className="price-card__title--hot">
                    　　　希望加强其营销效果的人　　　
                  </h3>
                  <div className="price-card__head--hot">
                    <p className="price-card__plan">固定利率计划</p>
                    <p className="price-card__hot-price">
                      <strong className="price-card__hot-price--strong">
                        50,000{" "}
                      </strong>
                      日元
                      <span className="price-card__unit">/月起</span>
                    </p>
                    <p className="price-card__taxin">(含税55,000日元起)</p>
                    <ul className="price-card__list">
                      <li className="price-card__item">
                        3个月合同: 60,000 日元/月 (66,000 日元 含税)
                      </li>
                      <li className="price-card__item">
                        6个月合同: 55,000 日元/月 (60,500 日元 含税)
                      </li>
                      <li className="price-card__item">
                        年度合同: 50,000 日元/月 (55,000 日元 含税)
                      </li>
                    </ul>
                    <p className="price-card__notes">
                      ※ 需要签订至少三个月的合同。
                    </p>
                  </div>
                  <div className="price-card__body">
                    <p className="price-card__initial">初始费用</p>
                    <p className="price-card__cost--free">免费的</p>
                  </div>
                </div>
                <div className="price-card">
                  <h3 className="price-card__title">
                    　　　希望尝试该活动的人　　　
                  </h3>
                  <div className="price-card__head">
                    <p className="price-card__plan">现收现付计划</p>
                    <p className="price-card__price">
                      <strong className="price-card__price--strong">
                        80,000{" "}
                      </strong>
                      日元
                      <span className="price-card__unit">/次</span>
                    </p>
                    <p className="price-card__taxin">(88,000日元含税)</p>
                  </div>
                  <div className="price-card__body">
                    <p className="price-card__initial">
                      初始费用<small className="price-card__small">※</small>
                    </p>
                    <p className="price-card__cost">
                      <strong className="price-card__price--strong">
                        50,000{" "}
                      </strong>
                      日元
                    </p>
                    <p className="price-card__taxin">(含税55,000日元)</p>
                    <p className="price-card__notes">※只针对第一份合同。</p>
                  </div>
                </div>
              </div>
              <p className="index__lead">联系我们了解其他活动的价格。</p>
              <a
                className="ga-contact-price price__link"
                href="https://hub.park-lot.com/document-download"
                target="_blank"
                rel="noreferrer"
              >
                查询收费情况
              </a>
            </div>
          </section>
          <section className="qa index">
            <div className="qa__container l-container">
              <h3 className="index__title">常见问题</h3>
              <div className="qa__box">
                <dl className="qa__list">
                  <dt className="qa__title">
                    在这个价格下，你到底能开展多少次活动？是否有任何额外的费用？
                  </dt>
                  <dd className="qa__data">
                    如果你注册了一个固定价格的计划，你可以在没有额外费用的情况下开展任意数量的活动。
                  </dd>
                </dl>
                <dl className="qa__list">
                  <dt className="qa__title">运动最早什么时候可以开始？</dt>
                  <dd className="qa__data">
                    如果你有一个Twitter账户、活动名称、礼物、活动期间和提交推文的图片，你可以在短短10分钟内开始你的活动。
                  </dd>
                </dl>
                <dl className="qa__list">
                  <dt className="qa__title">你能代表获奖用户送出礼物吗？</dt>
                  <dd className="qa__data">
                    不向中奖用户提供递送服务。获奖者的交货信息可以用CSV格式输出。
                  </dd>
                </dl>
              </div>
            </div>
          </section>
          <div className="contact">
            <p className="contact__text">
              现在就下载3分钟的PARKLoT
              <br />
              和成功案例!
            </p>
            <a
              className="ga-contact-middle contact__link btn-white"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              下载该文件（免费）
            </a>
          </div>
        </main>
      </Layout>
      {isShowFollowBtn && <FollowBtn />}
    </>
  )
}

export default Index
